<template>
  <div :style="{ backgroundColor: '#000' }" class="background">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Login with Spotify</h5>
        <hr>
        <div class="button-housing">
          <div v-show="loading === true">
            <Spinner/>
          </div>
          <div v-show="loading === false">
            <button class="spotify-button" @click.prevent="spotify">
              <i class="fab fa-spotify" style="color: #1DB954;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner"
import {auth} from "@/firebase-service";

export default {
  name: "Login",
  components: {Spinner},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    firebaseToken() {
      return this.$route.query.token || null;
    }
  },
  methods: {
    spotify() {
      this.loading = true
      location.href = "/api/auth/login";
    }
  },
  created() {
    if (this.firebaseToken !== null) {
      this.loading = true;

      auth().setPersistence(auth.Auth.Persistence.LOCAL).then(() => {
        return auth().signInWithCustomToken(this.firebaseToken).then(value => {
          this.$router.push("/");
          return value;
        }).catch(reason => {
          console.log("error: " + reason);
        })
      }).catch(reason => {
        console.log(reason);
      })
    }
  },
}
</script>

<style scoped>

body {
  background-color: #cccccc;
}

.card {
  position: fixed;
  top: 40%;
  left: 40%;
  right: 40%;

  -webkit-box-shadow: 3px 3px 30px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 3px 30px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 3px 30px 3px rgba(0, 0, 0, 0.5);
}

.card-body {
  text-align: center;
}

.button-housing {
  font-size: 4em;
  padding-top: 5%;
  padding-bottom: 5%;
}

.spotify-button {
  all: unset;
  cursor: pointer;
}

</style>