export default class WebSocketAdapter {

    static webSocket: WebSocket | undefined;
    static currentResource: string | undefined;

    public static connect(resourceDescriptor: string): void {
        if (this.currentResource === resourceDescriptor) return; // no need to reconnect when already connected double connections
        this.close(); // just to insure there are no duplicates

        this.webSocket = new WebSocket("wss://spotify-server.luis.team:8443" + resourceDescriptor);
        this.currentResource = resourceDescriptor;

        this.webSocket.onopen = () => {
            console.log("connected to wss://spotify-server.luis.team:8443" + resourceDescriptor)
        }

        this.webSocket.onmessage = (message: MessageEvent) => {
            console.log("Received message from wss://spotify-server.luis.team:8443" + resourceDescriptor + " saying: " + message.data)
        }

        this.webSocket.onclose = (event: CloseEvent) => {
            console.log("websocket was closed due to " + event.code + " " + event.reason)

            this.currentResource = undefined;
            this.webSocket = undefined;
        }
    }

    static close(): void {
        if (this.webSocket !== undefined) {
            this.webSocket?.close();
        }
    }

}
