import Vue from 'vue'
import Vuex from 'vuex'
import SpotifyWebApi from "spotify-web-api-js";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: false,
    accessTokenTime: 0,
    spotifyApi: new SpotifyWebApi(),
    currentUser: undefined,
  },
  mutations: {
    setAuth: (state, to) => {
      state.auth = to;
    },
    updateSpotifyApi: (state, to) => {
      state.spotifyApi.setAccessToken(to);
      state.accessTokenTime = new Date().getTime();
    },
    setCurrentUser: (state, to) => {
      state.currentUser = to;
    }
  },
  getters: {
    getAuth: (state) => {
      return state.auth;
    },
    getSpotifyApi: (state) => {
      if (state.accessTokenTime + 3600000 > new Date().getTime()) {
        axios.post("/api/auth/refresh").then(value => {
          state.spotifyApi.setAccessToken(value.data.accessToken);
          state.accessTokenTime = new Date().getTime();
        }).catch(reason => {
          console.log("error while getting new accessToken " + reason)
        })
      }

      return state.spotifyApi;
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    }
  }
})
