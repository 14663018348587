<template>
  <div>
    <div id="wrapper" class="d-flex">

      <!-- Sidebar -->
      <div id="sidebar-wrapper" class="bg-light border-right">
        <div class="sidebar-heading">
          <router-link style="text-decoration: none; color: #1398eb;" to="/">Luis Spotify</router-link>
        </div>
        <div class="list-group list-group-flush">
          <router-link class="list-group-item list-group-item-action bg-light" to="/dashboard/overview">Overview
          </router-link>
          <router-link class="list-group-item list-group-item-action bg-light" to="/dashboard/test">Shortcuts
          </router-link>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper">

        <nav class="navbar navbar-expand-md navbar-light bg-light">
          <div class="navbar-collapse">
            <ul class="navbar-nav ml-auto">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <router-link class="nav-link" to="/dashboard/profile">Profile ({{ loggedIn }})</router-link>
                </li>
              </ul>
            </ul>
          </div>
        </nav>

        <div class="container-fluid">
          <router-view/>
        </div>
        <!-- /#page-content-wrapper -->

      </div>
      <!-- /#wrapper -->
    </div>
  </div>
</template>

<script>
import WebSocketAdapter from "@/websocket/index";

export default {
  name: "Dashboard",
  methods: {
    connect: (resourceDescriptor) => {
      WebSocketAdapter.connect(resourceDescriptor);
    }
  },
  beforeDestroy() {
    WebSocketAdapter.close();
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getAuth;
    }
  }
}
</script>

<style scoped>
.router-link-active {
  font-weight: bold;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.5rem;
  text-align: center;
}

#sidebar-wrapper .sidebar-heading link {
  text-decoration: none;
  color: yellow;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

}

.container-fluid {
  padding: 1em;
}
</style>