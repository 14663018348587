import Vue from 'vue'
import store from "@/store/index"
import VueRouter, {RouteConfig} from 'vue-router'

// Components
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import PageNotFound from '@/views/PageNotFound.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard/',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "parent",
        redirect: {path: "overview"},
      },
      {
        path: "overview",
        component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Overview.vue'),
      },
      {
        path: "listen/:roomId",
        component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Listen.vue'),
      },
      {
        path: "profile",
        component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Profile.vue'),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
});

// check if somebody ist logged in
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getAuth) {
      next({name: 'Login'})
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
