import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import {auth, db} from "@/firebase-service";

Vue.config.productionTip = false

auth().onAuthStateChanged(async (user) => {
    if (user) {
        store.commit("setAuth", true);
        console.log("uid: " + auth().currentUser?.uid);
        const doc = await db.doc("users/" + auth().currentUser?.uid + "/credentials/spotify").get();

        //set default headers for axios
        axios.interceptors.request.use(async config => {
            const token = await auth().currentUser?.getIdToken();
            config.headers.authorization = 'Bearer ' + token;
            console.log("Token: " + token);

            return config;
        }, (error) => {
            return Promise.reject(error)
        });

        axios.defaults.headers.common = {
            "Content-Type": "application/json"
        }

        store.commit("updateSpotifyApi", doc.data()?.access_token);

        store.commit("setCurrentUser", user);
    } else {
        store.commit("setCurrentUser", undefined);
    }

    // Okay I feel like this could take veeeery long

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
});
