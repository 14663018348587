import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/performance"

const firebaseConfig = {
    apiKey: "AIzaSyB2smNxneTX-xDZEg3FDvIP0FZdAwKV42E",
    authDomain: "luis-team.firebaseapp.com",
    databaseURL: "https://luis-team.firebaseio.com",
    projectId: "luis-team",
    storageBucket: "luis-team.appspot.com",
    messagingSenderId: "361498338349",
    appId: "1:361498338349:web:e044045515c124c69ec4a4",
    measurementId: "G-CHKJ2DTG47"
};

firebase.initializeApp(firebaseConfig);
firebase.performance();

export const auth = firebase.auth;
export const db = firebase.firestore();
export default firebase;