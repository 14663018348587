<template>
  <div>
    <h1>Well this is a 404</h1>
  </div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>